//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'
import usePublishedContent from '~/composables/usePublishedContent'
import useStylingOptions from '~/composables/useStylingOptions'

export default defineComponent({
  name: 'RgBannerText',
  inheritAttrs: false,
  props: {
    image: {
      type: Object,
      default: () => {}
    },
    gradient: {
      type: String,
      default: null
    },
    headline: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    textColor: {
      type: String,
      default: 'light'
    },
    alignment: {
      type: String,
      default: 'left'
    },
    button: {
      type: Array,
      default: () => []
    },
    releaseDate: {
      type: String,
      default: null
    },
    endDate: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { checkReleaseState } = usePublishedContent()

    const { classTextColor, classAlignment, classGradientColor } = useStylingOptions(props.textColor, props.gradient, props.alignment)

    const showBanner = computed(() => checkReleaseState(props))

    return {
      showBanner,
      classTextColor,
      classAlignment,
      classGradientColor
    }
  }
})
