import { computed } from '@nuxtjs/composition-api'

const useStylingOptions = (textColor, gradientColor, alignment) => {
  const classTextColor = computed(() => {
    return textColor === 'light' ? 'text-white' : 'text-black'
  })

  const classGradientColor = computed(() => {
    switch (gradientColor) {
      case 'light':
        return 'from-[rgba(255,255,255,0.6)]'
      case 'dark':
        return 'from-[rgba(87,87,87,0.8)]'
      default:
        return ''
    }
  })

  const classAlignment = computed(() => {
    return alignment === 'left' ? 'items-start' : 'items-end'
  })

  return {
    classTextColor,
    classGradientColor,
    classAlignment
  }
}

export default useStylingOptions
